import axios from "axios";
import { TOKEN_NAME } from "../helper/constants";
import {
  dispatchNotification,
  showLoader,
  hideLoader,
  eraseCookie,
  getCookie,
  isDevEnv,
} from "../helper/utils";
import { LOGIN_ROUTE } from "../routes/routesConstant";

const BASE_URL = "https://jmdfieldforce.uat.ril.com/api/";

const client = axios.create({
  baseURL: BASE_URL,
  responseType: "text/plain",
  withCredentials: true,
  timeout: 50000,
});
let count = 0;
const request = function (
  { method, url, headers = {}, data = {}, responseType = "text/plain" },
  showErrorToast = true
) {
  if (isDevEnv()) {
    let token = getCookie(TOKEN_NAME);
    if (token) {
      headers.Authorization = `Bearer ${token}`;
      headers.isdev = true;
    }
  }
  if (count == 0) showLoader({ show: true });
  count++;
  const onSuccess = function (response) {
    count--;
    hideLoader();
    if (response.status === 200) {
      return response;
    }
    return response;
  };

  const onError = function (error) {
    count--;
    hideLoader();
    if (error.response.status === 403 || error.response.status === 401) {
      eraseCookie(TOKEN_NAME);
    }
    let message = "Something Went Wrong";
    let errorMessage;

    errorMessage = JSON.parse(error.response?.data)?.data?.errorMessage;

    dispatchNotification({
      type: "error",
      timeout: 3000,
      message: errorMessage || message,
    });
    return Promise.reject(errorMessage || message);
  };

  const payload = {
    method,
    url,
    headers: {
      ...headers,
    },
    data: data,
    responseType,
  };
  return client(payload).then(onSuccess).catch(onError);
};

export default request;
